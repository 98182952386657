import React from 'react';
import './scss/styles.scss';
import 'bootstrap/dist/css/bootstrap.min.css';
import {initializeConnector, Web3ReactProvider} from "@web3-react/core";
import {MetaMask} from "@web3-react/metamask";
import {Button} from "react-bootstrap";
import Chain from "./components/Chain";

function App() {
    const Metamask = initializeConnector<MetaMask>((actions) => new MetaMask({ actions }))
    const reactConnectors = [Metamask]

    return (
      <Web3ReactProvider connectors={reactConnectors}>
          <nav className="navbar navbar-expand-lg navbar-dark navbar-custom fixed-top">
              <div className="container px-5">
                  <a className="navbar-brand" href="#page-top">Council of Frogs</a>
                  <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarResponsive" aria-controls="navbarResponsive" aria-expanded="false" aria-label="Toggle navigation"><span className="navbar-toggler-icon"></span></button>
                  <div className="collapse navbar-collapse" id="navbarResponsive">
                      <ul className="navbar-nav ms-auto">
                          <li className="nav-item"><a className="nav-link" href="#purpose">Purpose</a></li>
                          <li className="nav-item"><a className="nav-link" href="#adoption">Adoption centre</a></li>
                          <li className="nav-item"><a className="nav-link" href="https://leap.shadowkite.io/" target={'_blank'}>Frog bridge</a></li>
                          <li className="nav-item"><a className="nav-link" href="#history">History</a></li>
                          <li className="nav-item"><a className="nav-link" href="#community">About</a></li>
                      </ul>
                  </div>
              </div>
          </nav>
          <header className="masthead text-center text-white">
              <div className="masthead-content">
                  <div className="container px-5">
                      <h1 className="masthead-heading mb-0">Council of Frogs</h1>
                      <h2 className="masthead-subheading mb-0">Make the Leap to another blockchain.</h2>
                  </div>
              </div>
              <div className="bg-circle-1 bg-circle"></div>
          </header>
          <section id="purpose">
              <div className="container px-5">
                  <div className="row gx-5 align-items-center">
                      <div className="col-lg-6 order-lg-2">
                          <div className="p-5"><img className="img-fluid rounded-circle" src="https://raw.githubusercontent.com/shadowkite/council-of-frogs/master/images/1337.png" alt="..." /></div>
                      </div>
                      <div className="col-lg-6 order-lg-1">
                          <div className="p-5">
                              <h2 className="display-4">The purpose.</h2>
                              <p>Your crimes are known. Magical creatures living on multiple blockchains at once, quietly deciding your fate. Will you be judged harshly, or will you be fren?</p>
                              <p>The Members of the Council use a Multichain bridge (EVM), thus can be found over the whole Blockchain space and multiple marketplaces.</p>
                              <p>Proud owners of Members of the Council can send their frogs to other blockchains using the <a href={'https://leap.shadowkite.io/'} target={'_blank'}>Bridge application page</a>. Not sending frogs from chain to chain every now and then, will be frowned upon by the Elders of the Council. Be warned. Ribbit.</p>
                          </div>
                      </div>
                  </div>
              </div>
          </section>
          <section id="adoption">
              <div className="container px-5">
                  <div className="row gx-5 align-items-center">
                      <div className="col-lg-6">
                          <div className="p-5"><img className="img-fluid rounded-circle" src="https://raw.githubusercontent.com/shadowkite/council-of-frogs/master/images/1630.png" alt="..." /></div>
                      </div>
                      <div className="col-lg-6">
                          <div className="p-5">
                              <h2 className="display-4">Adoption centre.</h2>
                              <p>Some of our Members are not yet adopted, and are still in the powerful hands of a dark entity, known as a <code>Contract</code>. The members tried fighting this entity with Spoons and Katanas, and even psychological warfare like hypnosis, judgmental facial expressions and the silent treatment. To no avail. </p>
                              <p>Help the Council by adopting a Council Member on DogeChain or Shibarium.</p>
                              { true ?  <Chain /> : <i>Coming soon!</i> }
                          </div>
                      </div>
                  </div>
              </div>
          </section>
          <section id="history">
              <div className="container px-5">
                  <div className="row gx-5 align-items-center">
                      <div className="col-lg-6">
                          <div className="p-5">
                              <h2 className="display-4">History</h2>
                              <p>The Council of Frogs started out as a fundraiser for an opensource Multichain bridge, almost 2000 frogs were minted on SmartBCH. Sadly, the chain died. ☠️</p>
                              <p>Since we didn't want our Members of the Council stuck in limbo, you will be able to adopt the remaining frogs on other chains.</p>

                              <h2 className="display-4">Developers &lt;3</h2>
                              <p>Are you an NFT developer? Want to build a bridge as well? Contact us!</p>
                              <p>We love the Blockchain space and want to move it forward, our bridge code is opensource and we love to help you set it up.</p>
                          </div>
                      </div>
                      <div className="col-lg-6">
                          <div className="p-5"><img className="img-fluid rounded-circle" src="https://raw.githubusercontent.com/shadowkite/council-of-frogs/master/images/92.png" alt="..." /></div>
                      </div>
                  </div>
              </div>
          </section>
          <section id="community">
              <div className="container px-5">
                  <div className="row gx-5 align-items-center">
                      <div className="col-lg-6 order-lg-1">
                          <div className="p-5">
                              <img className="img-fluid rounded-circle" src="https://raw.githubusercontent.com/shadowkite/council-of-frogs/master/images/100.png" alt="..." />
                          </div>
                      </div>
                      <div className="col-lg-6 order-lg-2">
                          <div className="p-5">
                              <h2 className="display-4">Community</h2>
                              <p>
                                  <a href={'https://t.me/counciloffrogs'} target={'_blank'}>
                                    Join our other adopters on Telegram
                                  </a>
                              </p>
                              <p>
                                  <a href={'https://twitter.com/FrogsCouncil'} target={'_blank'}>
                                    Check out our Twitter / X
                                  </a>
                              </p>
                          </div>
                      </div>
                  </div>
              </div>
          </section>
          <section id={"team"}>
              <div className="container px-5">
                  <div className="row gx-5">
                      <div className="col-lg-6">
                          <div className="p-5 h-100 project">
                              <div className="d-flex h-100">
                                  <div className="project-text w-100 my-auto text-end text-lg-left team-shadowkite">
                                      <h1>Shadowkite</h1>
                                      <small>Artist & Developer</small>
                                      <p>
                                          <a href="https://t.me/shadowkite" target="_blank">Drop me a message</a>
                                      </p>
                                  </div>
                              </div>
                          </div>
                      </div>
                      <div className="col-lg-6">
                          <div className="p-5 h-100 project">
                              <div className="d-flex h-100">
                                  <div className="project-text w-100 my-auto text-start text-lg-left team-insanity">
                                      <h1>Insanity</h1>
                                      <small>Community manager</small>
                                      <p>
                                          <a href="https://t.me/insanity3431" target="_blank">Drop me a message</a>
                                      </p>
                                  </div>
                              </div>
                          </div>
                      </div>
                  </div>
              </div>
          </section>
          <footer className="py-5 bg-black">
              <div className="container px-5">
                  <p className="m-0 text-center text-white small">Copyright &copy; Council of Frogs 2022-2023</p>
              </div>
              <div className="container px-5">
                  <p className="m-0 text-end text-white small">
                      <a href={'https://shadowkite.io'}>
                          <img src={'/assets/shadowkite-white-small.png'}  alt={'Shadowkite'}/>
                      </a>
                  </p>
              </div>
          </footer>
      </Web3ReactProvider>
    );
}

export default App;
