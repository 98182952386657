import {useWeb3React} from "@web3-react/core";
import React, {useEffect, useState} from "react";
import nftAbi from "../artifacts/@openzeppelin/contracts/token/ERC721/ERC721.sol/ERC721.json";
import adoptionCentreAbi from "../artifacts/contracts/C0FAdoptionCentre.sol/x0C0F_AdoptionCentre.json";
import {Contract, ethers} from "ethers";
import {Button, InputGroup} from "react-bootstrap";
import InputGroupText from "react-bootstrap/InputGroupText";

function Chain () {
    const { isActive, chainId, accounts, provider, connector } = useWeb3React();
    const [connected, setConnected] = useState(false)
    const [connecting, setConnecting] = useState(false)
    const [nft, setNft] = useState<Contract>()
    const [adoptionCentre, setAdoptionCentre] = useState<Contract>()
    const [costs, setCosts] = useState(0)
    const [discounted, setDiscounted] = useState(false)
    const [currency, setCurrency] = useState('');
    const [available, setAvailable] = useState(0);
    const [chain, setChain] = useState(0);
    const [amount, setAmount] = useState(1);
    const [minted, setMinted] = useState(false);

    const owner = '0xc3EEbD580d94190Bf74ABdDda8a7964820892098'

    const dcCA = '0x20966a7aacA1a3f61A87B5Bd050EA15fc20C32c9'
    const shibCA = '0x53Bb68c338928b72BeDb82f5Cf5B84C6b8B203f0'
    const nftCA = '0xE46727bb5B84d574eCCa7e562A36c23525FcF8Dc'

    const activate = async (_chain : any) => {
        await connector.activate(_chain)
        setChain(_chain);
    }

    const connect = async () => {
        if(!isActive) {
            return;
        }
        setConnecting(true)
        let tmpNft;
        let tmpAdoptionCentre;
        switch(chainId) {
            case 2000:
                tmpNft = new Contract(nftCA, nftAbi.abi, provider?.getSigner())
                tmpAdoptionCentre = new Contract(dcCA, adoptionCentreAbi.abi, provider?.getSigner());
                setCurrency('WDOGE');
                break
            case 109:
                tmpNft = new Contract(nftCA, nftAbi.abi, provider?.getSigner())
                tmpAdoptionCentre = new Contract(shibCA, adoptionCentreAbi.abi, provider?.getSigner());
                setCurrency('BONE');
                break;
        }
        if(tmpNft) {
            setNft(tmpNft)
            setAvailable(await tmpNft.balanceOf('0xc3EEbD580d94190Bf74ABdDda8a7964820892098'))
        }

        setAdoptionCentre(tmpAdoptionCentre)
        if(tmpAdoptionCentre && accounts) {
            setDiscounted(await tmpAdoptionCentre.hasDiscount(accounts[0]))
            setCosts(await tmpAdoptionCentre.getCosts(accounts[0]))
        }
        setConnecting(false)
        setConnected(true)
    }

    useEffect( () => {
        if(isActive && provider && !connected && !connecting) {
            connect();
        }
    })

    const mint = async () => {
        setMinted(false)
        if(adoptionCentre) {
            try {
                await adoptionCentre.mint(amount, {value: (costs * amount).toString()})
                setMinted(true)
            } catch (e) {
                
            }
        }
    };

    const switchToDC = async () => {
        if(chain === 2000 && chainId === 2000) {
            return;
        }
        if(connector.deactivate) {
            await connector.deactivate();
        }
        await activate(2000);
        setConnected(false)
    }

    const switchToShib = async () => {
        if(chain === 109 && chainId === 109) {
            return;
        }
        if(connector.deactivate) {
            await connector.deactivate();
        }
        await activate(109);
        setConnected(false)
    }

    const amountSubstract = async () => {
        setAmount(Math.max(amount - 1, 1));
    }

    const amountAdd = async () => {
        setAmount(Math.min(amount + 1, 20));
    }

    const approve = async () => {
        if(nft) {
            await nft.setApprovalForAll(dcCA, true);
        }
    }

    const chainSwitch = <>
        <p>
            <Button onClick={switchToDC}>
                <img width={'20'} src={'assets/dogecoin-doge-logo.png'} /> DogeChain
            </Button> &nbsp;
            <Button onClick={switchToShib}>
                <img width={'20'} src={'assets/shiba-inu-shib-logo.png'} /> Shibarium
            </Button>
        </p>
    </>

    return <div>
        { true ? chainSwitch : <></> }
        { connected && (chainId === 109 || chainId === 2000) ?
            <>
                <p>There are currently <strong>{available.toString()}</strong> frogs waiting for a new owner.</p>

                { chainId === 2000 && discounted ? <p>
                    <img src={'https://api.cryptor.at/nightmares/rat/3'} width={64} height={64} /><br/>
                    You have been endorsed by the Elders of the Council.
                    They heard you are taking care of their $RAT frens. You are now also fren, and thereby allowed to say 'Ribbit' publicly. It is advised to use these sacred word non-cautiously. Furthermore, you received a 20% discount, already deducted!</p> : <></> }
                <InputGroup>
                    <Button onClick={amountSubstract}>-</Button>
                    <InputGroupText>{amount}</InputGroupText>
                    <Button onClick={amountAdd}>+</Button>
                    <Button variant={"danger"} onClick={mint}>Adopt [{ethers.utils.formatEther(costs.toString())} {currency} each]</Button>
                </InputGroup>

                { minted ? <p>Frogs have landed upon your wallet! RIBBIT!</p> : <></> }
            </> : <>
                <Button onClick={activate}>Connect Metamask</Button>
            </>
        }
    </div>
}

export default Chain;